import React from 'react'
import PropTypes from 'prop-types'
import { BeatLoader } from 'react-spinners'
import style from './LoadingPage.styl'

// if (isLoading && pastDelay) {
//   return <p>Loading...</p>;
// } else if (error && !isLoading) {
//   return <p>Error!</p>;
// } else {
//   return null;
// }
const LoadingPage = ({ error = null }) => (
  <>
    {!error && (
      <div className={style.container}>
        <BeatLoader color="#0c3891" size={30} />
      </div>
    )}
  </>
)

LoadingPage.propTypes = {
  error: PropTypes.shape({})
}

LoadingPage.defaultProps = {
  error: null
}

export default LoadingPage
