import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import 'core-js/es'
import 'intersection-observer'
import 'whatwg-fetch'
import { isDevelopment } from './utils/Constants'
import { unregister } from './registerServiceWorker'
import '!style-loader!css-loader!normalize.css' //eslint-disable-line
import '!style-loader!css-loader!react-day-picker/lib/style.css' //eslint-disable-line
import '!style-loader!css-loader!react-table/react-table.css' //eslint-disable-line
import '!style-loader!css-loader!rc-tooltip/assets/bootstrap.css' //eslint-disable-line
import '!style-loader!css-loader!react-perfect-scrollbar/dist/css/styles.css' //eslint-disable-line
import '!style-loader!css-loader!react-toastify/dist/ReactToastify.css' //eslint-disable-line
import '!style-loader!css-loader!react-draft-wysiwyg/dist/react-draft-wysiwyg.css' //eslint-disable-line
import '!style-loader!css-loader!react-toggle/style.css' //eslint-disable-line

import { LoadingPage } from './components'
import './style/main.styl'

const App = React.lazy(() => import('./App'))

if (
  (window.location.pathname === '' || window.location.pathname === '/') &&
  !(window.location.search !== '' || window.location.hash)
) {
  window.location.href = process.env.HOMEPAGE_URL
} else {
  ReactDOM.render(
    <Suspense fallback={<LoadingPage />}>
      <App />
    </Suspense>,
    document.getElementById('root')
  )
}

if (!isDevelopment) {
  Sentry.init({
    dsn: process.env.SENTRY,
    environment: process.env.DEPLOY_ENV,
    release: `${process.env.REV || ''}-${process.env.DEPLOY_ENV}`,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2
  })
}
unregister()
