export const isDevelopment = process.env.NODE_ENV === 'development'
export const isSpectrum = process.env.APP === 'spectrum'

export const tokenServices =
  'tEzirFgYZFKHkUdfM0gyDlk5RRpgIUA7qWW0nArGluQzA2zE3wIV7v8hzkAHV6AJl1gAs0ERwbzo3oJR2w1yfavQ3pGt1OOyvVK5'

export const tokenSpectrum = '8Rf2NdzkGfX3grkhQktk0OMxTEeHZ4yn'

export const eventbriteUrl =
  'https://www.eventbrite.com/oauth/authorize?response_type=code&client_id='

export const virtualTicketUrl = 'https://tickets.evensi.com/virtual'

export const featuresPlan = {
  interest_based_matching: 'interest_matching',
  geo_targeting: 'geo_targeting',
  detailed_reporting: 'detailed_reporting',
  top_results: 'top_results',
  email_invitations: 'email_invite',
  social_media_ads: 'social_media_advertising'
}

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const availableLanguages = ['it', 'en', 'es', 'de', 'fr', 'pt']

export const langLocale = {
  it: 'it_IT',
  en: 'en_US',
  es: 'es_ES',
  de: 'de_DE',
  fr: 'fr_FR',
  pt: 'pt_PT'
}

export const langSwitch = {
  it: 'Italiano',
  en: 'English',
  es: 'Español',
  de: 'Deutsch',
  fr: 'Français',
  pt: 'Português'
}

export const isAmPm = {
  it: false,
  en: true,
  es: false,
  de: false,
  fr: false,
  pt: false
}

export const eventOrigins = {
  FB: 'Facebook',
  EB: 'Eventbrite',
  EF: 'Eventful',
  MU: 'MeetUp',
  LFM: 'LastFM',
  LFMFest: 'LastFM',
  TK: 'Tickeri',
  TM: 'Ticketmaster',
  CS: 'CitySpark'
}

export const schemeColorPlans = {
  small: '87b2ea',
  medium: '57c471',
  big: '00bcda'
}

export const dayPickerStyles = {
  selected: {
    color: 'white',
    backgroundColor: '#00bcda'
  }
}

export const promotionColor = {
  small: '#00bcda',
  medium: '#00bcda',
  big: '#00bcda',
  Custom: '#00bcda'
}

export const PlacesAutocompleteStyles = {
  root: { position: 'relative' },
  input: {
    width: '100%',
    padding: '.75rem'
  },
  autocompleteContainer: {
    backgroundColor: '#f3f3f3',
    border: '1px solid #dee6ec',
    maxWidth: '25rem',
    left: '50%',
    transform: 'translateX(-50%)',
    opacity: 1,
    zIndex: 3
  },
  autocompleteItem: {
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #dee6ec',
    color: '#141414'
  },
  autocompleteItemActive: { color: '#333840' }
}

export const subscriptionFeatures = {
  2: {
    name: 'STARTER',
    description: 'ideal_occasional_event_promoter',
    compare: '30%'
  },
  4: {
    name: 'PRO',
    description: 'ideal_weekly_event_promoters',
    compare: '40%'
  },
  8: {
    name: 'ENTERPRISE',
    description: 'ideal_frequent_event_promoters',
    compare: '50%'
  }
}

export const classesCalendarDefault = {
  container: 'DayPicker',
  wrapper: 'DayPicker-wrapper',
  interactionDisabled: 'DayPicker--interactionDisabled',
  months: 'DayPicker-Months',
  month: 'DayPicker-Month',

  navBar: 'DayPicker-NavBar',
  navButtonPrev: 'DayPicker-NavButton DayPicker-NavButton--prev',
  navButtonNext: 'DayPicker-NavButton DayPicker-NavButton--next',
  navButtonInteractionDisabled: 'DayPicker-NavButton--interactionDisabled',

  caption: 'DayPicker-Caption',
  weekdays: 'DayPicker-Weekdays',
  weekdaysRow: 'DayPicker-WeekdaysRow',
  weekday: 'DayPicker-Weekday',
  body: 'DayPicker-Body',
  week: 'DayPicker-Week',
  weekNumber: 'DayPicker-WeekNumber',
  day: 'DayPicker-Day',
  footer: 'DayPicker-Footer',
  todayButton: 'DayPicker-TodayButton',

  // default modifiers
  today: 'today',
  selected: 'selected',
  disabled: 'disabled',
  outside: 'outside'
}

export const dashboardInviteType = 'dashboard_invite'

export const redirectCreationType = 'redirect_creation'

export const fastEventCrationFrom = 'fasteventcreation'

export const evnSourceEvensi = 'Evensi'

export const catFastSelection = {
  1: [1],
  2: [4],
  3: [9],
  4: [17],
  5: [14],
  6: [16],
  7: [18, 21, 93, 95, 97, 98, 99, 100, 101, 102, 103, 108, 110, 125, 126, 128]
}

export const goalTypes = ['sell_tickets', 'brand_awareness']
